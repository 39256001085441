var orderFormHasErrors = false;
var orderFormPayload = {};

$(document).ready(function () {
  setupAccordion();
  setupMainmenu();
  if (document.getElementById("topbar")) {
    setupFixedTopbar();
  }
  initOrder();
  priceCalc();
  productCount();
  closeOrder();
  closeComplete();
  checkInvoiceForm();
  goToShipping();
  orderComplete();

  fetchDeliveryAdress();
  fetchInvoiceAdress();

  setupOrderButtonEvents();
  checkUrlOnLoad();

  $("#btnEdit").hide();
  $("#btnConfirm").hide();
  $("#shippingDataConfirmation").hide();
});

function setupAccordion() {
  // Accordion
  var acc = document.getElementsByClassName("accordion_btn");
  var i;

  for (i = 0; i < acc.length; i++) {
    acc[i].addEventListener("click", function () {
      this.classList.toggle("active");
      var panel = this.nextElementSibling;
      if (panel.style.maxHeight) {
        panel.style.maxHeight = null;
      } else {
        panel.style.maxHeight = panel.scrollHeight + "px";
      }
    });
  }
}

function setupMainmenu() {
  $("#btnMainmenu").on("click", function (event) {
    $(this).toggleClass("active");
  });
}

function setupFixedTopbar() {
  window.onscroll = function () {
    setupFixedTopbar();
  };
  var header = document.getElementById("top");
  var navbar = document.getElementById("topbar");
  var sticky =
    document.getElementById("features").offsetTop - navbar.offsetHeight;

  if (window.pageYOffset >= sticky) {
    header.classList.add("sticky");
  } else {
    header.classList.remove("sticky");
  }
}

function checkInvoiceForm() {
  $("#check-invoiceaddress").on("click", function () {
    if ($("input#check-invoiceaddress").is(":checked")) {
      $("#factuurgegevens").hide();
      $("input[name='billingGivenName']").prop("required", false);
      $("input[name='billingFamilyName']").prop("required", false);
      $("input[name='billingPostcode']").prop("required", false);
      $("input[name='billingNumber']").prop("required", false);
      $("input[name='billingStreet']").prop("required", false);
      $("input[name='billingLocation']").prop("required", false);
    } else {
      $("#factuurgegevens").show();
      $("input[name='billingGivenName']").prop("required", true);
      $("input[name='billingFamilyName']").prop("required", true);
      $("input[name='billingPostcode']").prop("required", true);
      $("input[name='billingNumber']").prop("required", true);
      $("input[name='billingStreet']").prop("required", true);
      $("input[name='billingLocation']").prop("required", true);
    }
  });
}

function goToShipping() {
  $("#btnNext").on("click", function () {
    event.preventDefault();
    $("#shippingData").show();
    $("#shippingDataConfirmation").hide();
    $("#product").hide();
    $("#coupon").hide();
    $("#cart_footer p").show();
    $("#btnNext").hide();
    $("#btnConfirm").show();
    $("#btnEdit").hide();
    $("#btnSubmit").hide();
    $("body").addClass("insert-data");
    $("#coupon input").val("");
    UrlOrder2();
  });
}

function validatePostcode(postcode) {
  var regex = /^[1-9][0-9]{3} ?([A-RT-Za-rt-z][A-Za-z]|[Ss][BCE-RT-Zbce-rt-z])$/;
  return regex.test(postcode);
}

function validateEmail(email) {
  var regex = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
  return regex.test(email);
}

function validateNumber(number) {
  var regex = /^[0-9]*$/;
  return regex.test(number);
}

function showInputError(fields) {
  fields.forEach((input) => {
    if (input.hasAttribute("required") && input.value == "") {
      input.classList.add("input-error");
    }
  });
  $("#btnConfirm").after(
    '<div class="error_message">Controleer of je alle velden juist hebt ingevuld.</div>'
  );
  document.getElementById("btnConfirm").disabled = true;
}

function UrlOrder1() {
  var state = { orderstep: 1 };
  window.history.pushState(state, "", "winkelmandje");
}

function UrlOrder2() {
  var state = { orderstep: 2 };
  window.history.pushState(state, "", "bestellen");
}

function cleanUrl() {
  window.history.pushState("", "", "one");
}

function validateOrderForm() {
  // build message payload with form fields
  //
  orderFormPayload = {};
  const fields = [...document.querySelectorAll("#cart_wrapper input")];
  fields.forEach((input) => {
    if (
      input.type === "text" ||
      input.type === "hidden" ||
      input.type === "email"
    ) {
      if (input.value.trim() !== "") orderFormPayload[input.name] = input.value;
      return;
    }

    if (input.type === "checkbox" && input.checked) {
      orderFormPayload[input.name] = input.value;
      return;
    }

    if (input.type === "radio" && input.checked) {
      orderFormPayload[input.name] = input.value;
      return;
    }
  });

  // validate all input fields
  //TODO hack
  fields.forEach((input) => {
    if (input.hasAttribute("required") && input.value.trim() == "") {
      input.classList.add("input-error");
      orderFormHasErrors = true;
      return;
    }
  });

  // validate postcode
  if (orderFormPayload["country"] == "NL") {
    if (!validatePostcode($("#deliveryPostcode").val())) {
      showInputError(fields);
      $("#deliveryPostcode").addClass("input-error");
      orderFormHasErrors = true;
      return;
    }
    if (!$("#check-invoiceaddress").attr("checked")) {
      if (!validatePostcode($("#invoicePostcode").val())) {
        $("#invoicePostcode").addClass("input-error");
        orderFormHasErrors = true;
        return;
      }
    }
  }

  // validate email
  if (!validateEmail($("#input-email").val())) {
    showInputError(fields);
    $("#input-email").addClass("input-error");
    orderFormHasErrors = true;
    return;
  }

  // validate huisnummer
  if (!validateNumber($('input[name="number"]').val())) {
    showInputError(fields);
    $('input[name="number"]').addClass("input-error");
    orderFormHasErrors = true;
    return;
  }
}

function hideConfirmationPage() {
  $("#btnConfirm").show();
  $("#btnEdit").hide();
  $("#btnSubmit").hide();

  $("#summary").show();
  $("#shippingData").show();
  $("#shippingDataConfirmation").hide();
}

function showConfirmationPage() {
  //TODO show confirmation page (hide form?)
  //TODO this should be on the confirmation page
  $("#btnConfirm").hide();
  $("#btnEdit").show();
  $("#btnSubmit").show();

  $("#summary").hide();
  $("#shippingData").hide();
  $("#shippingDataConfirmation").show();

  // email
  var emailConfirmation = '<div class="confirmation-title">Email</div>';
  emailConfirmation +=
    '<div class="confirmation-value">' +
    orderFormPayload.emailAddress +
    "</div>";

  // naam
  var shippingAddressConfirmation =
    '<div class="confirmation-title">Naam</div>';
  shippingAddressConfirmation +=
    '<div class="confirmation-value">' + orderFormPayload.givenName + " ";
  if (typeof orderFormPayload.betweenName != "undefined") {
    shippingAddressConfirmation += orderFormPayload.betweenName + " ";
  }
  shippingAddressConfirmation += orderFormPayload.familyName + "</div>";

  // bedrijfsnaam
  if (typeof orderFormPayload.companyName != "undefined") {
    shippingAddressConfirmation +=
      '<div class="confirmation-title">Bedrijfsnaam</div>';
    shippingAddressConfirmation +=
      '<div class="confirmation-value">' +
      orderFormPayload.companyName +
      "</div>";
  }

  // land
  if (orderFormPayload.country.toUpperCase() == "NL") {
    shippingAddressConfirmation += '<div class="confirmation-title">Land</div>';
    shippingAddressConfirmation +=
      '<div class="confirmation-value">Nederland</div>';
  } else if (orderFormPayload.country.toUpperCase() == "BE") {
    shippingAddressConfirmation += '<div class="confirmation-title">Land</div>';
    shippingAddressConfirmation +=
      '<div class="confirmation-value">België</div>';
    } else if (orderFormPayload.country.toUpperCase() == "DE") {
    shippingAddressConfirmation += '<div class="confirmation-title">Land</div>';
    shippingAddressConfirmation +=
        '<div class="confirmation-value">Duitsland</div>';
    } else if (orderFormPayload.country.toUpperCase() == "LU") {
    shippingAddressConfirmation += '<div class="confirmation-title">Land</div>';
    shippingAddressConfirmation +=
    '<div class="confirmation-value">Luxemburg</div>';
    } else if (orderFormPayload.country.toUpperCase() == "FR") {
    shippingAddressConfirmation += '<div class="confirmation-title">Land</div>';
    shippingAddressConfirmation +=
    '<div class="confirmation-value">Frankrijk</div>';
  } else {
  }

  // straat en nummer
  var streetAndNumber = orderFormPayload.street + " " + orderFormPayload.number;
  if (typeof orderFormPayload.numberExt != "undefined") {
    streetAndNumber += orderFormPayload.numberExt;
  }
  shippingAddressConfirmation += '<div class="confirmation-title">Adres</div>';
  shippingAddressConfirmation +=
    '<div class="confirmation-value">' + streetAndNumber + "</div>";

  // postcode
  shippingAddressConfirmation +=
    '<div class="confirmation-title">Postcode</div>';
  shippingAddressConfirmation +=
    '<div class="confirmation-value">' + orderFormPayload.postcode + "</div>";

  // woonplaats
  shippingAddressConfirmation +=
    '<div class="confirmation-title">Woonplaats</div>';
  shippingAddressConfirmation +=
    '<div class="confirmation-value">' + orderFormPayload.location + "</div>";

  // if billing address
  if ($("input#check-invoiceaddress").prop("checked") == false) {
    // billing naam
    var billingAddressConfirmation =
      '<div class="confirmation-title">Naam</div>';
    billingAddressConfirmation +=
      '<div class="confirmation-value">' +
      orderFormPayload.billingGivenName +
      " ";
    if (typeof orderFormPayload.billingBetweenName != "undefined") {
      billingAddressConfirmation += orderFormPayload.billingBetweenName + " ";
    }
    billingAddressConfirmation += orderFormPayload.billingFamilyName + "</div>";

    // billing bedrijfsnaam
    if (typeof orderFormPayload.billingCompanyName != "undefined") {
      billingAddressConfirmation +=
        '<div class="confirmation-title">Bedrijfsnaam</div>';
      billingAddressConfirmation +=
        '<div class="confirmation-value">' +
        orderFormPayload.billingCompanyName +
        "</div>";
    }

    // billing land
    if (orderFormPayload.billingCountry.toUpperCase() == "NL") {
      billingAddressConfirmation +=
        '<div class="confirmation-title">Land</div>';
      billingAddressConfirmation +=
        '<div class="confirmation-value">Nederland</div>';
    } else if (orderFormPayload.billingCountry.toUpperCase() == "BE") {
      billingAddressConfirmation +=
        '<div class="confirmation-title">Land</div>';
      billingAddressConfirmation +=
        '<div class="confirmation-value">België</div>';
    } else if (orderFormPayload.billingCountry.toUpperCase() == "LU") {
        billingAddressConfirmation +=
          '<div class="confirmation-title">Land</div>';
        billingAddressConfirmation +=
        '<div class="confirmation-value">Luxemburg</div>';
    } else if (orderFormPayload.billingCountry.toUpperCase() == "DE") {
        billingAddressConfirmation +=
            '<div class="confirmation-title">Land</div>';
        billingAddressConfirmation +=
            '<div class="confirmation-value">Duitsland</div>';

    } else if (orderFormPayload.billingCountry.toUpperCase() == "FR") {
        billingAddressConfirmation +=
            '<div class="confirmation-title">Land</div>';
        billingAddressConfirmation +=
            '<div class="confirmation-value">Frankrijk</div>';
        }

    // billing straat en nummer
    var streetAndNumber =
      orderFormPayload.billingStreet + " " + orderFormPayload.billingNumber;
    if (typeof orderFormPayload.billingNumberExt != "undefined") {
      streetAndNumber += orderFormPayload.billingNumberExt;
    }
    billingAddressConfirmation += '<div class="confirmation-title">Adres</div>';
    billingAddressConfirmation +=
      '<div class="confirmation-value">' + streetAndNumber + "</div>";

    // billing postcode
    billingAddressConfirmation +=
      '<div class="confirmation-title">Postcode</div>';
    billingAddressConfirmation +=
      '<div class="confirmation-value">' +
      orderFormPayload.billingPostcode +
      "</div>";

    // billing woonplaats
    billingAddressConfirmation +=
      '<div class="confirmation-title">Woonplaats</div>';
    billingAddressConfirmation +=
      '<div class="confirmation-value">' +
      orderFormPayload.billingLocation +
      "</div>";
  }

  // laten zien
  $("#confirm-shipping-address").html(
    "<h3>Bezorgadres</h3>" + shippingAddressConfirmation
  );
  $("#confirm-email-address").html("<h3>Emailadres</h3>" + emailConfirmation);

  if ($("input#check-invoiceaddress").prop("checked") == false) {
    $("#confirm-billing-address").show();
    $("#confirm-billing-address").html(
      "<h3>Factuuradres</h3>" + billingAddressConfirmation
    );
  } else {
    $("#confirm-billing-address").hide();
  }

  //  // scroll to top
  // $("#cart_wrapper").scrollTop = 0;
}

function setupOrderButtonEvents() {
  $("#btnEdit").on("click", function () {
    // reset errors
    orderFormHasErrors = false;
    hideConfirmationPage();
  });
  $("#btnConfirm").on("click", function () {
    // reset errors
    orderFormHasErrors = false;
    validateOrderForm();

    if (orderFormHasErrors == false) {
      // if no errors are found, go to confirmation page
      showConfirmationPage();
    }
  });

  $("#btnSubmit").on("click", function () {
    // all fields validated, now continue to payment
    $("#btnConfirm").next(".error_message").remove();
    $("#btnSubmit").next(".error_message").remove();

    fetch("https://webshop.flitsmeister.io/order", {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(orderFormPayload),
    })
      .then((res) => {
        if (res.status === 200) {
          return res.json();
        } else if (res.status === 400) {
          throw Error("invalid_input");
        } else {
          throw Error("server_issues");
        }
      })
      .then((res) => {
        window.location = res.paymentURL;
      })
      .catch((ex) => {
        console.error(ex);
        if (ex.message === "server_issues") {
          $("#btnSubmit").after(
            '<div class="error_message">Het is niet gelukt om je bestelling te plaatsen, Probeer het later nog eens.</div>'
          );
        }
        if (ex.message === "invalid_input") {
          showInputError(fields);
        }
      });
  });

  $("#cart_wrapper input").focusin(function () {
    if ($(this).hasClass("input-error")) {
      $(this).removeClass("input-error");
    }
  });
  $("#cart_wrapper input").focusout(function () {
    document.getElementById("btnConfirm").disabled = false;
    $("#btnConfirm").next(".error_message").remove();
  });
}

window.addEventListener("popstate", function (e) {
  var state = e.state;
  checkUrl(state);
});

var getUrlParameter = function getUrlParameter(sParam) {
  var sPageURL = window.location.search.substring(1),
    sURLVariables = sPageURL.split("&"),
    sParameterName,
    i;

  for (i = 0; i < sURLVariables.length; i++) {
    sParameterName = sURLVariables[i].split("=");

    if (sParameterName[0] === sParam) {
      return sParameterName[1] === undefined
        ? true
        : decodeURIComponent(sParameterName[1]);
    }
  }
};

function checkUrlOnLoad() {
  var orderstep = getUrlParameter("orderstep");
  if (typeof orderstep != "undefined") {
    processOrderstep(orderstep);
  }
}

function checkUrl(state) {
  if (typeof state.orderstep == "undefined") {
    hideOrder();
  }
  processOrderstep(state.orderstep);
}

function processOrderstep(orderstep) {
  if (orderstep == "1") {
    hideOrder();
    showOrderStep1();
  }
  if (orderstep == "2") {
    hideOrder();
    goToShipping();
  }
}

function showOrderStep1() {
  $("#cart_modal").css("display", "flex");
  $("#cart_wrapper").show();
  $("#orderfailed_wrapper").hide();
  $("body").addClass("lock");
  UrlOrder1();
}
function initOrder() {
  $(".openOrder").on("click", function () {
    event.preventDefault();
    $("#btnEdit").hide();
    $("#btnConfirm").hide();
    $("#shippingDataConfirmation").hide();
    showOrderStep1();

    disableDeliveryStreetAndCity();
    $("#editDeliveryStreetAndCity").click(function (event) {
      event.preventDefault();
      enableDeliveryStreetAndCity();
      $(this).hide();
    });

    disableInvoiceStreetAndCity();
    $("#editInvoiceStreetAndCity").click(function (event) {
      event.preventDefault();
      enableInvoiceStreetAndCity();
      $(this).hide();
    });
  });
}

function disableDeliveryStreetAndCity() {
  $("#deliveryStreet").prop("disabled", true);
  $("#deliveryCity").prop("disabled", true);
}

function enableDeliveryStreetAndCity() {
  $("#deliveryStreet").prop("disabled", false);
  $("#deliveryCity").prop("disabled", false);
}

function disableInvoiceStreetAndCity() {
  $("#invoiceStreet").prop("disabled", true);
  $("#invoiceCity").prop("disabled", true);
}

function enableInvoiceStreetAndCity() {
  $("#invoiceStreet").prop("disabled", false);
  $("#invoiceCity").prop("disabled", false);
}

function hideOrder() {
  $("#cart_modal").css("display", "none");
  $("#shippingData").hide();
  $("#shippingDataConfirmation").hide();
  $("#product").show();
  $("#coupon").show();
  $("#summary").show();
  $("#cart_footer p").hide();
  $("#btnNext").show();
  $("#btnSubmit").hide();
  $("#btnConfirm").hide();
  $("#cart_wrapper").hide();
  $("body").removeClass("insert-data");
  $("body").removeClass("lock");
  $(".error_message").remove();
  // TODO: remove parameters from url if order id is filled
  cleanUrl();
}

function closeOrder() {
  $("#closeOrder")
    .on("click", function () {
      event.preventDefault();
      hideOrder();
    })
    .children()
    .click(function (e) {
      return false;
    });
}

function closeComplete() {
  $(".closeComplete").on("click", function () {
    event.preventDefault();
    $("#ordercomplete_wrapper").hide();
    $("#orderfailed_wrapper").hide();
    $("#ordernotfound_wrapper").hide();
    $("#orderservererror_wrapper").hide();
    $("body").removeClass("lock");
    cleanUrl();
  });
}

const STATUS = {
  "0": "Wacht op betaling",
  "1": "Betaling voltooid",

  "-1": "Betaling geannuleerd",
  "-2": "Betalingsverzoek verlopen",
  "-3": "Betalingsverzoek gefaald",
};

function orderComplete() {
  const sp = new URLSearchParams(window.location.search);
  if (sp.get("orderID")) {
    const orderID = sp.get("orderID");

    $("body").addClass("lock");

    fetch("https://webshop.flitsmeister.io/orders/" + orderID)
      .then((res) => {
        if (res.status === 200) {
          return res.json();
        } else if (res.status === 404) {
          throw Error("order_not_found"); // TODO: deze kan wellicht samen met server issues, algemene state.. neit gevonden of server probleem?
        } else {
          throw Error("server_issues");
        }
      })
      .then((json) => {
        if (json.status != 1 && json.status != 2) {
          throw Error;
        }
        document.querySelector("#orderLastUpdated").innerHTML =
          "Laatste update: " +
          new Date(json.updated_at).toLocaleDateString("nl-NL", {
            year: "numeric",
            month: "long",
            day: "numeric",
          });

        // TODO: HTML fields opzoeken en vullen met order status
        // Ordernummer vullen
        // Betaalstatus
        $("#ordercomplete_wrapper").show();
        $("#orderID").html(orderID);
        $("#ordercomplete_wrapper").show();
        console.log(json);
      })
      .catch((ex) => {
        // console.error(ex);
        // TODO: Gianni errors showen
        $("#orderfailed_wrapper").show();
        if (ex.message === "order_not_found") {
          $("#ordernotfound_wrapper").show();
        }
        if (ex.message === "server_issues") {
          $("#orderservererror_wrapper").show();
        }
      });
  }
}

async function fetchAddress(postcode, number, numberExt, street, city) {
  const url = "https://webshop.flitsmeister.io/address";
  const data = {
    postcode: postcode.value.replace(/\s/g, "").toUpperCase(),
    number: parseInt(number.value),
    numberExt: numberExt.value || null,
  };
  try {
    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    });
    const json = await response.json();
    // Hier ff een check doen dat er data is...
    if ("street" in json) {
      $("#deliveryAddress").show();
      street.value = json.street;
      city.value = json.location;
    }
  } catch (error) {
    console.error("Error:", error);
    // $("#deliveryAddress").hide();
  }
}

function fetchDeliveryAdress() {
  $("#deliveryHouseNumber").keyup(async function () {
    fetchAddress(
      (postcode = document.getElementById("deliveryPostcode")),
      (number = document.getElementById("deliveryHouseNumber")),
      (numberExt = document.getElementById("deliveryHouseNumberAddon")),
      (street = document.getElementById("deliveryStreet")),
      (city = document.getElementById("deliveryCity"))
    );
  });
}

function fetchInvoiceAdress() {
  $("#invoiceHouseNumber").keyup(async function () {
    fetchAddress(
      (postcode = document.getElementById("invoicePostcode")),
      (number = document.getElementById("invoiceHouseNumber")),
      (numberExt = document.getElementById("invoiceHouseNumberAddon")),
      (street = document.getElementById("invoiceStreet")),
      (city = document.getElementById("invoiceCity"))
    );
  });
}

function priceCalc() {
  var totalPrice = $("#productCount").val() * 49.95;
  if (totalPrice >= 49.95) {
    $("#totalPrice").html(totalPrice.toFixed(2));
  } else {
    totalprice = 49.95;
  }
}

function productCount() {
  $("#productCount").keyup(function () {
    priceCalc();
  });
  $("#productCount").focusin(function () {
    $("#productCount").val("");
  });
  $("#productCount").focusout(function () {
    if ($("#productCount").val() < 1 || isNaN($("#productCount").val())) {
      $("#productCount").val(1);
    }
  });
  $("#productCount").change(function () {
    if ($("#productCount").val() < 1 || isNaN($("#productCount").val())) {
      $("#productCount").val(1);
    }
    if ($("#productCount").val() > 1000) {
      $("#productCount").val(1000);
    }
    priceCalc();
  });
}

$("#coupon button").on("click", function () {
  if (!$("#coupon").hasClass("error")) {
    $("#coupon").addClass("error");
    $("#coupon input").addClass("input-error");
    $(this).after(
      "<span class='error_message'>Kortingscode niet gevonden</span>"
    );
  }
  if ($("#coupon").hasClass("error")) {
    $("#coupon").focusin(function () {
      $("#coupon").removeClass("error");
      $("#coupon input").removeClass("input-error");
      $("#coupon button").next(".error_message").remove();
    });
  }
});

$("#btnMainmenu").on("click", function () {
  $("#mainmenu").toggle();
});
