/**
 * -------------------------------------------------------------------------
 *  Core object
 * -------------------------------------------------------------------------
 */
var core = new function(){

  var self = this;

  /**
  * -----------------------------------------------------------------------
  *  Post JSON
  * -----------------------------------------------------------------------
  *  Get JSON from URL
  */
  self.postJSON = function(url, callback, data) {
    var xhr = new XMLHttpRequest();
    xhr.open('POST', url, true);
    xhr.setRequestHeader("Content-type", "application/json");
    //xhr.setRequestHeader("Access-Control-Allow-Headers", "x-requested-with");
    xhr.responseType = 'json';
    xhr.onload = function() {
      var status = xhr.status;
      if (status == 200) {
        callback(null, xhr.response);
      } else {
        callback(status);
      }
    };
    xhr.send(JSON.stringify(data));
  }

  /**
  * -----------------------------------------------------------------------
  *  Get JSON
  * -----------------------------------------------------------------------
  *  Get JSON from URL
  */
  self.getJSON = function(url, callback) {
    var xhr = new XMLHttpRequest();
    xhr.open('get', url, true);
    xhr.responseType = 'json';
    xhr.onload = function() {
      var status = xhr.status;
      if (status == 200) {
        callback(null, xhr.response);
      } else {
        callback(status);
      }
    };
    xhr.send();
  }

  /**
  * -----------------------------------------------------------------------
  *  Empty Cookie
  * -----------------------------------------------------------------------
  */
  self.removeCookie = function(){
    document.cookie = "";
  }

  /**
  * -----------------------------------------------------------------------
  *  Remove From Cookie
  * -----------------------------------------------------------------------
  */
  self.removeCookieVar = function(variable){
    var needle = variable+"=", cookiejar = document.cookie;
    result = cookiejar.indexOf(needle);
    if (result >= 0) {
      var strBegin = cookiejar.substring(0, result);
      while(cookiejar.charAt(result) != ";") result = result+1;
      var strEnd = cookiejar.substring(result);
      document.cookie = strBegin+strEnd;
    }
  }

  /**
  * -----------------------------------------------------------------------
  *  Set Cookie Variable
  * -----------------------------------------------------------------------
  */
  self.setCookieVar = function(variable, value) {
    var needle = variable+"=", cookiejar = document.cookie;
    result = cookiejar.indexOf(needle);
    if (result >= 0) {
      var strBegin = cookiejar.substring(0, result);
      while(cookiejar.charAt(result) != ";") result = result+1;
      var strEnd = cookiejar.substring(result);
      document.cookie = strBegin+variable+"="+value+";"+strEnd;
    } else {
      document.cookie = variable+"="+value+";"+cookiejar;
    }
  }

  /**
  * -----------------------------------------------------------------------
  *  Get Cookies Variable
  * -----------------------------------------------------------------------
  */
  self.getCookieVar = function(variable){
     var needle = variable+"=";
     var cookiejar = document.cookie.split(';');
     for(var i = 0; i < cookiejar.length; i++) {
         var c = cookiejar[i];
         while (c.charAt(0)==' ') c = c.substring(1);
         if (c.indexOf(needle) == 0) return c.substring(needle.length,c.length);
     }
     return "";
  }

}

  $(document).ready(function() {

    $(".herotype").typed({
      strings: ["voorkomt boetes.", "bespaart tijd.", "berekent routes."],
      typeSpeed: 0,
      backDelay: 3000,
      loop: 999999
    });

    $("[data-openmenu]").on("click", function(event) {
      event.preventDefault();
      $("#mnav").removeClass("dn").addClass("dib");
      $("#dnav").hide();
      $("body").addClass("overflow-hidden");
    });

    $("#close-mnav-link").on("click", function(event) {
      event.preventDefault();
      $("#mnav").removeClass("dib").addClass("dn");
      $("#dnav").show();
      $("body").removeClass("overflow-hidden");

    });
  });
