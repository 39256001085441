$(function() {
    var pageIndex = $("body").data("page");
    
    switch (pageIndex) {
        case "pro":
            processPro("pro");
            break;
        case "bedrijf":
            processBedrijf();
            break;
        case "parkeren":
            processParkeren();
            break;
        case "parkeren-vergelijk":
            processParkerenVergelijk();
            break;
        case "ritregistratie":
            processTripRegistration();
            break;
        case "invite":
            processInvite();
            break;
        default:
            break;
    }
});


// continue with the page
var currentPage = window.location.hash ? parseInt(window.location.hash.replace('#', ''), 10) : 1;

var preload = function(arrayOfImages) {
    $(arrayOfImages).each(function(){
        $('<img/>')[0].src = this;
        // Alternatively you could use:
        // (new Image()).src = this;
    });
}

// Language selector
var languageButton = $("[data-languageselector]");
var languageDropdown = $("[data-languagedropdown]");

function languageselectorOpen(){
    languageDropdown.css("visibility", "visible")
    languageDropdown.css("transform", "translate(-50%, -100%)")
    languageDropdown.css("height", "auto")
    languageDropdown.css("opacity", "1")
    languageDropdown.css("overflow", "auto")
    languageButton.addClass("open")
}

function languageselectorClose(){
    languageDropdown.css("visibility", "hidden")
    languageDropdown.css("opacity", "0")
    languageDropdown.css("transform", "translate(-50%, -95%)")
    languageDropdown.css("height", "1px")
    languageDropdown.css("overflow", "hidden")
    languageButton.removeClass("open")
}

languageButton.click(function() {
    if(languageButton.hasClass('open')){
        languageselectorClose();
    } else {
        languageselectorOpen();
    }
});

var processBedrijf = function() {

    $('.profile img[data-hover]').each(function(){
        preload([
            $(this).data('hover'),
        ]);
    });

    $(".profile").hover(function() {
        var img = $(this).find("img");
        var hover = img.attr("data-hover");
        img.attr("src", hover);
    }, function() {
        var img = $(this).find("img");
        var def = img.attr("data-default");
        img.attr("src", def);
    })
}

var hideError = function(ev) {
    $("#errorMsg").val("");
    if(ev && ev.keyCode == 13)
    {
        $(this).trigger("enterKey");
    }
}

// var positionModalCloseButton = function() {

//     var offset = $("#pro-modal-container").offset();
//     var top = offset.top - $(window).scrollTop();
//     var left = offset.left;
//     var width = $("#pro-modal-container").width();

//     $("#close-modal-arrow").show();

//     $("#close-modal-arrow").css({top: top-23, left: left+width+68, position:'absolute'});
// }

var processPro = function(type) {
    processAccordion();

    //TODO temp
    // positionModalCloseButton();

    // $( window ).resize(function() {
    //      positionModalCloseButton();
    // })


    // $('#pro1Email').keyup(function(ev) {
    //     hideError(ev);
    //     hideSignup();
    // });
    // $('#pro1Name').keyup(function(ev) {
    //     hideError(ev);
    //     hideSignup();
    // });

    // $('#pro2Email').keyup(function(ev) {
    //     hideError(ev);
    //     hideSignup();
    // });
    // // $('#pro2Name').keyup(function(ev) {
    // //     hideError(ev);
    // //     hideSignup();
    // // });

    // $("#proReveal1year").click(function(event) {
    //     event.preventDefault();

    //     hideSignup();

    //     $("#pro-modal-1year").removeClass("dn");
    //     $("#pro-modal-2year").addClass("dn");
    //     $("#pro-modal").removeClass("dn");

    //     positionModalCloseButton();
    // })

    // $("#proReveal2year").click(function(event) {
    //     event.preventDefault();

    //     hideSignup();

    //     $("#pro-modal-1year").addClass("dn");
    //     $("#pro-modal-2year").removeClass("dn");
    //     $("#pro-modal").removeClass("dn");

    //     positionModalCloseButton();
    // })

    // $("#pro-modal").click(function(e) {
    //     if (e.target !== this)
    //       return;

    //     $("#pro-modal").addClass("dn");
    // })

    // $(".close-modal").click(function(e) {
    //     e.preventDefault();
    //     $("#pro-modal").addClass("dn");
    // })

    // when a user presses the escape button
    // $(document).keyup(function(e) {
    //     if (e.keyCode === 13) {
    //         validateProForm(type);
    //     }
    //     if (e.keyCode === 27) {
    //         $("#pro-modal").addClass("dn");
    //     }
    //   });

    // when a user presses the submit button
    // $(".proSubmit").click(function(event) {
    //     event.preventDefault();
    //     validateProForm(type);
    // });

    // $('#pro1Name').bind("enterKey",function(e){ validateProForm(type); });
    // $('#pro1Email').bind("enterKey",function(e){ validateProForm(type); });
    // $('#pro2Name').bind("enterKey",function(e){ validateProForm(type); });
    // $('#pro2Email').bind("enterKey",function(e){ validateProForm(type); });

    function openMainmenu(){
        document.body.classList.add("mainmenu_open");
    }

    function closeMainmenu(){
        document.body.classList.add("closing");
        setTimeout(
            function() {
                document.body.classList.remove("mainmenu_open");
                document.body.classList.remove("closing");
            },
            300
        );
    }

    var processMainmenu = function(){
        if(document.body.classList.contains("mainmenu_open") == false){
            openMainmenu();
        } else{
            closeMainmenu();
        }
    }
    
    document.getElementById("btn_mainmenu").addEventListener("click", processMainmenu);
    document.getElementById("mainmenu_backdrop").addEventListener("click", processMainmenu);
    document.addEventListener('keydown', function(event){
        if(event.key === "Escape"){
            closeMainmenu();
        }
    });
}

var validateProForm = function(type) {

    if (type == "pro") {

        var productId = 'fm.pro.1y';

        var name = '';
        var email = '';
        var coupon = null;
        var userPassword = ''

        if ($("#pro-modal-1year").is(":visible")) {
            name = $("#pro1Name").val();
            email = $("#pro1Email").val();
            coupon = $("#pro1Discount").val();
            userPassword = $("#buy-form-signup-password-1year").val();
        } else if ($("#pro-modal-2year").is(":visible")) {
            name = $("#pro2Name").val();
            email = $("#pro2Email").val();
            coupon = $("#pro2Discount").val();
            productId = 'fm.pro.2y';
            userPassword = $("#buy-form-signup-password-2year").val();
        }

        if (!userPassword.trim())
            userPassword = null

        if (isFilled(name) == false) {

            $("#errorMsg").html("Je hebt geen geldige naam ingevuld.");
            $("#errorMsg").show();

        }
        else if (isEmail(email) == false) {

            $("#errorMsg").html("Je hebt geen geldig emailadres ingevuld.");
            $("#errorMsg").show();

        } else {

            const params = {
                givenName: name,
                emailAddress: email,
                products: [{
                    'productId': productId,
                    'amount': 1
                }]
            };

            if (isFilled(coupon)) {

                // validate coupon code
                $.ajax("https://webshop.flitsmeister.io/order/coupon", {
                    type: "POST",
                    data: { productId: productId, code: coupon },
                    complete: function(xhr, textStatus) {

                        if (textStatus == 'error' && xhr.status != 404) {

                            // failed
                            $("#errorMsg").html("Er is iets mis gegaan.");
                            $("#errorMsg").show();

                        } else if (xhr.status == 404) {

                            // failed
                            $("#errorMsg").html("Je hebt geen geldige kortingscode ingevuld.");
                            $("#errorMsg").show();

                        } else {

                            // succeeded continue with the payment
                            validate(email, userPassword, params, coupon)
                        }

                    },
                 });

            } else {
                validate(email, userPassword, params, null)
            }

        }
    }
}

var isEmail = function(email) {
    var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return regex.test(email);
}

var isFilled = function(value) {
    if (value == '' || value == 'null' || !value) {
        return false;
    }
    return true;
}

var appendReply = function(replyId, author, message, date) {

    $('#replies-' + replyId).append(
        '<div class="br2 bg-near-white pa3 mb2 pb2 ba b--black-10">' +
            '<div class="fw7 f6 mb2"> ' + author + ' </div>' +
            '<div class="gray lh-copy mb2"> ' + message + '  </div>' +
            '<div class="gray lh-copy tr f7"> ' + new Date(date).toLocaleDateString() + ' - ' + new Date(date).toLocaleTimeString() + ' </div>' +
        '</div>'
    );
}

var processAccordion = function() {
    // Accordion
    var acc = document.getElementsByClassName("accordion");
    var i;

    for (i = 0; i < acc.length; i++) {
        acc[i].addEventListener("click", function() {
            this.classList.toggle("active");
            var panel = this.nextElementSibling;
            if (panel.style.maxHeight) {
                panel.style.maxHeight = null;
            } else {
                panel.style.maxHeight = panel.scrollHeight + "px";
            }
        });
    }
}

var processModal = function(){
    // Control modal
    // TODO: Make this more generic
    var modal = $("[data-modal]");
    var modalBackdrop = $("[data-modalBackdrop]")
    var btnModalClose = $("[data-modalClose]")
    var btnModalOpen = $("[data-modalOpen]")

    function modalOpen() {
        modal.css("visibility", "visible");
        modal.css("opacity", "1");
        modal.css("overflow", "hidden");
    };

    function modalClose() {
        modal.css("visibility", "hidden");
        modal.css("opacity", "0");
        modal.css("overflow", "");
    };

    btnModalOpen.click(function() {
        modalOpen();
    });

    btnModalClose.click(function() {
        modalClose();
    });

    modalBackdrop.click(function() {
        modalClose();
    });

    document.onkeydown = function(evt) {
        evt = evt || window.event;
        if (evt.key === "Escape") {
            modalClose();
        }
    };
}

var processParkeren = function (){

    // modal open/close and autoplay video
    $("#btnPlayContainer").click(function() {

        // open modal window
        $("#parkeren-video-modal").removeClass("dn");
        $("#parkeren-video-modal").addClass("flex");

        // make videoplayer fullscreen and fluid by resizing
        $videoPlayer = $("#youtube-player");
        $body = $("body");

        $videoPlayer
                .attr('data-aspectRatio', $videoPlayer.height() / $videoPlayer.width())
                .removeAttr('height')
                .removeAttr('width');

                $(window).resize(function() {

                    var newWidth = $body.width();
                    newWidth = newWidth * 0.9;
                    $videoPlayer
                        .width(newWidth)
                        .height(newWidth * $videoPlayer.attr('data-aspectRatio'));

                }).resize();


        // start playing the embedded Youtube video
        $("#youtube-player")[0].src = "https://www.youtube.com/embed/gfGVnhnPAN8?controls=1&amp;autohide=1&amp;wmode=opaque&amp;enablejsapi=1&amp;widgetid=1&amp;autoplay=1";

    });


    $("#parkeren-video-modal").click(function() {

        $("#parkeren-video-modal").removeClass("flex");
        $("#parkeren-video-modal").addClass("dn");

        // stop playing the embedded Youtube video
        $("#youtube-player")[0].src = "";
    });

    processModal();

    processAccordion();
}

var throwPackageStore = function(xStore) {

    // flip package
    TweenMax.fromTo(package, {y:50, x:xStore+80, scale:0, opacity: 0}, {delay: 0, opacity: 1, y:-25, x: xStore+40, scale:1, rotation:720, duration:0.6, ease: Power2.easeOut });
    TweenMax.fromTo(package, {y:-25, x:xStore+40}, {delay: 0.6, y:50, x: xStore, rotation:0, scale: 0, opacity: 0, duration: 0.75, ease: Power2.easeOut });

    // // flip package
    // TweenMax.fromTo(package, {y:50, x:xStore, scale:0, opacity: 0}, {delay: 0, opacity: 1, y:-25, x: xStore+80, scale:1, rotation:720, duration:0.6, ease: Power2.easeOut });
    // TweenMax.fromTo(package, {y:-25, x:xStore+80}, {delay: 0.6, y:50, x: xStore+120, rotation:0, duration: 0.75, ease: Power2.easeOut });
}

var throwPackageHouse = function(xHouse) {

    // flip package
    TweenMax.fromTo(package, {y:50, x:xHouse, scale:0, opacity: 0}, {delay: 0, opacity: 1, y:-25, x: xHouse-80, scale:1, rotation:720, duration:0.6, ease: Power2.easeOut });
    TweenMax.fromTo(package, {y:-25, x:xHouse-80}, {delay: 0.6, y:50, x: xHouse-160, rotation:0, duration: 0.75, ease: Power2.easeOut });
}

var processParkerenVergelijk = function (){

    $("#parkeren-vergelijk-button").click(function(event) {
        event.preventDefault();

        window.location = 'flitsmeister://parking';
        setTimeout("window.location = './parkeren.html';", 1000);
    })


    var startDelay = 1.3;

    // yb logo
    TweenMax.set($("#box-left"), {transformOrigin:"100% 50%"});
    TweenMax.fromTo($("#box-left"), 0.4, {scale:0, right: -40}, {opacity:1, scale:1, right: 0, rotation:-7.5, delay:startDelay, ease:Bounce.easeOut})
    TweenMax.to($("#logo-yb"), 0.4, {right: 0, top: 10, rotation:-10, delay:startDelay+0.14, ease:Bounce.easeOut})

    // yb boom 1
    TweenMax.fromTo($("#logo-yb-boom-1"), 3.5,
    {opacity:0, top: 0, left: 0, rotation:30},
    {opacity:1, top: -150, left: 20, rotation:-30, delay:startDelay+0.3, ease:Power2.easeOut})

    // yb boom 2
    TweenMax.fromTo($("#logo-yb-boom-2"), 4,
    {opacity:0, top: 0.5, left: 10, rotation:30},
    {opacity:1, top: -150, left: -35, rotation:-30, delay:startDelay+0.5, ease:Power2.easeOut})

    // pm logo
    TweenMax.set($("#box-right"), {transformOrigin:"0% 50%"});
    TweenMax.fromTo($("#box-right"), 0.4, {scale:0, left: -40}, {opacity:1, scale:1, left: 0, rotation:7.5, delay:startDelay, ease:Bounce.easeOut})
    TweenMax.to($("#logo-pm"), 0.4, {left: 0, top:10, rotation:10, delay:startDelay+0.14, ease:Bounce.easeOut})

    // pm boom 1
    TweenMax.fromTo($("#logo-pm-boom-1"), 3.5,
    {opacity:0, top: 0, right: 0, rotation:30},
    {opacity:1, top: -150, right: -20, rotation:-30, delay:startDelay+0.3, ease:Power2.easeOut})

    // pm boom 2
    TweenMax.fromTo($("#logo-pm-boom-2"), 4,
    {opacity:0, top: 0.5, right: 10, rotation:30},
    {opacity:1, top: -150, right: 25, rotation:-30, delay:startDelay+0.5, ease:Power2.easeOut})

};

var processPurchasePayment = function(params, coupon) {

    if (coupon !== null || coupon !== '') {
        params.coupon = coupon;
    }

    fetch("https://webshop.flitsmeister.io/order", {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params),
    })
      .then((res) => {
        if (res.status !== 200) throw Error("server_issues");
        return res.json()
      })
      .then((res) => {
        window.location = res.redirectURL;
      })
      .catch((ex) => {
        $("#errorMsg").html("Er is iets mis gegaan.");
        $("#errorMsg").show();
      })
};

var validatePassword = function(password) {
    if (password.length < 5) {
      return false;
    }

    return true;
};

var validate = function(email, userPassword, params, coupon) {

    params.emailAddress = email

    // if password exists, skip this step and go to processPurchasePayment but with password as third argument
    if (userPassword != null) {
      if (!validatePassword(userPassword)) {
        $("#errorMsg").html(
          "Je wachtwoord moet uit minstens 5 tekens bestaan."
        );
        $("#errorMsg").show();
      } else {
        $.ajax("https://account.flitsmeister.app/register", {
            type: "POST",
            data: { username: email, password: userPassword, name: params.givenName },
            complete: function(xhr, textStatus) {
                if (textStatus == "error" && xhr.status != 200 && xhr.status != 201) {
                    $("#errorMsg").html("Er is iets mis gegaan.");
                    $("#errorMsg").show();
                } else if (xhr.status == 200 || xhr.status === 201) {
                    processPurchasePayment(params, coupon);
                }
            }
        });
      }
    } else {
      // validate user
      $.ajax("https://account.flitsmeister.app/verify", {
        type: "POST",
        data: { emailAddress: email },
        complete: function(xhr, textStatus) {
          if (textStatus == "error" && xhr.status != 200 && xhr.status != 404) {
            // failed
            $("#errorMsg").html("Er is iets mis gegaan.");
            $("#errorMsg").show();
          } else if (xhr.status == 200) {
            // succeeded, process to payment
            processPurchasePayment(params, coupon);
          } else if (xhr.status == 404) {
            // failed, show form again
            hideError();
            showSignup();
          } else {
            processPurchasePayment(params, coupon);
          }
        }
      });
    }
};

var showSignup = function() {
    $("#buy-form-signup-1year").show();
    $("#buy-form-signup-2year").show();
    $("#buy-form-buy-only-1year").hide();
    $("#buy-form-buy-only-2year").hide();
};

var hideSignup = function() {
    $("#buy-form-signup-password-1year").val("");
    $("#buy-form-signup-password-2year").val("");
    $("#buy-form-signup-1year").hide();
    $("#buy-form-signup-2year").hide();
    $("#buy-form-buy-only-1year").show();
    $("#buy-form-buy-only-2year").show();
};

// Check if user stopped scrolling
// var scrollStop = function (callback) {

// 	// Make sure a valid callback was provided
// 	if (!callback || typeof callback !== 'function') return;

// 	// Setup scrolling variable
// 	var isScrolling;

// 	// Listen for scroll events
// 	window.addEventListener('scroll', function (event) {

// 		// Clear our timeout throughout the scroll
// 		window.clearTimeout(isScrolling);

// 		// Set a timeout to run after scrolling ends
// 		isScrolling = setTimeout(function() {

// 			// Run the callback
// 			callback();

// 		}, 66);
// 	});
// };

// scrollStop(function () {
//     PageMenuEvents()
// });

var processTripRegistration = function() {
    processAccordion();
    
    var btnPlay = document.getElementById('btnPlayContainer');
    btnPlay.addEventListener("click", loadPlayer);

    // Load the tripregistration video
    // TODO: Make this more generic so we can use it for other video's
    function loadPlayer() {
        var videoModal = document.getElementById('video_modal');
        var btnClose = document.getElementById('btn_modal_close');
        var viewPortWidth = document.body.clientWidth;
        var viewPortHeight = document.body.clientHeight;
        
        var options = {
            id: 482567027,
            width: viewPortWidth * 0.9,
            height: viewPortHeight * 0.9,
            controls: false,
            loop: false
        };
        
        btnClose.classList.remove('dn');
        btnClose.classList.add('db');

        var player = new Vimeo.Player('video_tripregistration', options);
        player.setLoop(false)
        player.setMuted(false)
        player.setVolume(1);
        
        function playerUnload(){
            player.unload().then(function() {
                // The video has been unloaded
                videoModal.classList.add('dn');
                videoModal.classList.remove('flex');
                btnClose.classList.add('dn');
                btnClose.classList.remove('db');
            });
        }

        player.ready().then(function() {
            var videoPlayer = document.querySelector('#video_tripregistration iframe');

            window.onresize = function() {
                viewPortWidth = document.body.clientWidth;
                viewPortHeight = document.body.clientHeight;
                // newWidth = viewPort * 0.9;
                videoPlayer.setAttribute("width", viewPortWidth * 0.9)
                videoPlayer.setAttribute("height", viewPortHeight * 0.9)
            }
            
            // The player is ready
            videoModal.classList.remove('dn');
            videoModal.classList.add('flex');
            player.play();

            player.on('ended', playerUnload);
            videoModal.addEventListener("click", playerUnload);
            btnClose.addEventListener("click", playerUnload);

        });
    }

    window.addEventListener('scroll', function () {
        PageMenuEvents()
    });
    
    function PageMenuEvents(){
        scrollPos = window.scrollY
        offsetTolorence = document.getElementById('page_nav').offsetHeight,
    
        pageMenuTrigger = Math.round(document.getElementById('features').getBoundingClientRect().top + window.scrollY);
    
        if(scrollPos >= pageMenuTrigger - offsetTolorence){
            showPageMenu();
            pageMenuNavTriggers()
        }
        else{
            hidePageMenu();
        }
    }
    
    function showPageMenu(){
        document.getElementById('page_nav').classList.add("visible");
    }
    
    function hidePageMenu(){
        document.getElementById('page_nav').classList.remove("visible");
    }
    
    function pageMenuNavTriggers(){
        var scrollPos = window.scrollY
            elements = [...document.getElementsByClassName("page_nav_link")];
    
            featuresYPosTop = Math.round(document.getElementById('features').getBoundingClientRect().top + window.scrollY) - document.getElementById('page_nav').offsetHeight
            featuresYPosBottom = Math.round(document.getElementById('features').getBoundingClientRect().bottom + window.scrollY)
    
            privacyYPosTop = Math.round(document.getElementById('privacy').getBoundingClientRect().top + window.scrollY) - document.getElementById('page_nav').offsetHeight
            privacyYPosBottom = Math.round(document.getElementById('privacy').getBoundingClientRect().bottom + window.scrollY)
    
            subscriptionYPosTop = Math.round(document.getElementById('abonnement').getBoundingClientRect().top + window.scrollY) - document.getElementById('page_nav').offsetHeight
            subscriptionYPosBottom = Math.round(document.getElementById('abonnement').getBoundingClientRect().bottom + window.scrollY)
    
            faqYPosTop = Math.round(document.getElementById('faq').getBoundingClientRect().top + window.scrollY) - document.getElementById('page_nav').offsetHeight
            faqYPosBottom = Math.round(document.getElementById('faq').getBoundingClientRect().bottom + window.scrollY)
    
        function cleanElements(){
            [].forEach.call(elements, function(el) {
                el.classList.remove("active");
            });
        }
    
        if(scrollPos >= featuresYPosTop && scrollPos <= featuresYPosBottom){
            cleanElements()
            elements[0].classList.add("active")
        }
    
        if(scrollPos >= privacyYPosTop && scrollPos <= privacyYPosBottom){
            cleanElements()
            elements[1].classList.add("active")
        }
    
        if(scrollPos >= subscriptionYPosTop && scrollPos <= subscriptionYPosBottom){
            cleanElements()
            elements[2].classList.add("active")
        }
    
        if(scrollPos >= faqYPosTop && scrollPos <= faqYPosBottom){
            cleanElements()
            elements[3].classList.add("active")
        }
    
    }
    
};


var processInvite = function (){
    processAccordion();

    function replaceToDeeplink(){
        var deepLinkButton = document.getElementById("btn_deeplink");
        var appStoreButtons = [...document.getElementsByClassName("appstore_btn_hero")];
        console.log(appStoreButtons);

        [].forEach.call(appStoreButtons, function(el) {
            el.style.display = "none";
        });
        deepLinkButton.classList.add("flex");
        deepLinkButton.classList.remove("dn");
    }

    var checkOS = function() {
        var isIOS = /iPhone/.test(navigator.userAgent) && !window.MSStream;
            isAndroid = /android/i.test(navigator.userAgent)
        
        if (isIOS || isAndroid) {
            replaceToDeeplink()
        }
    }
    checkOS();
};